// ORDER_BUTTON_CREATE_ORDER: "RSC.ORDER.BUTTON.CREATE_ORDER", --> Reemplazado con el permiso de arriba. Replicar en otras vistas, deben separarse los permisos por zona

const resources = {
  // parent_id
  SIDEBAR: "sidebar",
  ORDERS: "orders",
  SHIPPING_GROUPS: "shipping_groups",
  SCHEDULE: "schedule",
  SOURCES: "sources",
  COURIER: "couriers",
  USECAPACITY: "usecapacity",
  ROUTES: "routes",
  COVERAGE: "coverage",
  LOCATIONS: "locations",
  CALENDAR: "calendar",
  INVENTORY: "inventory",
  CHANNEL: "channels",
  STOCK: "stock",
  ITEMS: "items",
  MASSIVE_LOAD: "massive_load",
  REPORTS: "reports",
  SETTINGS: "settings",

  // globals
  SETTINGS_BUTTON_GROUP_SIDEBAR: "RSC.SETTINGS.BUTTON_GROUP.SIDEBAR",
  SETTINGS_USER_SHOW_SECURITY_SCHEMAS_LIST: "RSC.SETTING.USER.SHOW.SECURITY.SCHEMAS.LIST",
  

  // ORDER
  OrdersGroup: "orders-group",
  ORDER_BUTTON_GROUP_SIDEBAR: "RSC.ORDER.BUTTON_GROUP.SIDEBAR",
  ORDER_ITEM_ORDER_SIDEBAR: "RSC.ORDER.ITEM.ORDER.SIDEBAR",
  ORDER_ITEM_SG_SIDEBAR: "RSC.ORDER.ITEM.SG.SIDEBAR",
  ORDER_ITEM_ORDER_CREATE_SIDEBAR: "RSC.ORDER.ITEM.ORDER.CREATE.SIDEBAR",
  
  OrListOrder: "or-list-order",
  ORDER_LIST_ORDER_LIST: "RSC.ORDER.LIST.ORDER_LIST",
  ORDER_PAGE_LIST: "RSC.ORDER.PAGE.LIST",
  ORDER_BUTTON_SHOW: "RSC.ORDER.BUTTON.SHOW",
  ORDER_BUTTON_EDIT: "RSC.ORDER.BUTTON.EDIT",
  ORDER_BUTTON_NEW_SIMPLE: "RSC.ORDER.BUTTON.NEW.SIMPLE",
  ORDER_BUTTON_NEW_COMPLEX: "RSC.ORDER.BUTTON.NEW.COMPLEX",

  OrFilterOrderId: "or-filter-order-id",
  ORDER_FILTER_ORDER_ID: "RSC.ORDER.FILTER.ORDER_ID",

  OrFilterChannel: "or-filter-channel",
  ORDER_FILTER_CHANNEL: "RSC.ORDER.FILTER.CHANNEL",

  OrFilterOrderStatus: "or-filter-order-status",
  ORDER_FILTER_CURRENT_STATUS: "RSC.ORDER.FILTER.CURRENT_STATUS",

  OrFilterCreationDate: "or-filter-creation-date",
  ORDER_FILTER_CREATION_DATE: "RSC.ORDER.FILTER.CREATION_DATE",

  OrFilterCustomFolio: "or-filter-custom-folio",
  ORDER_FILTER_CUSTOM_FOLIO: "RSC.ORDER.FILTER.CUSTOM_FOLIO",

  OrFilterCustomerId: "or-filter-customer-id",
  ORDER_FILTER_CUSTOMER_ID: "RSC.ORDER.FILTER.CUSTOMER_ID",

  OrFilterSalesChannelId: "or-filter-sales-channel-id",
  ORDER_FILTER_SALES_CHANNEL_ID: "RSC.ORDER.FILTER.SALES_CHANNEL_ID",

  OrFilterCustomerEmail: "or-filter-customer-email",
  ORDER_FILTER_CUSTOMER_EMAIL: "RSC.ORDER.FILTER.CUSTOMER_EMAIL",

  OrFilterShippingType: "or-filter-shipping-type",
  ORDER_FILTER_SHIPPING_TYPE: "RSC.ORDER.FILTER.SHIPPING_TYPE",

  OrFilterIncrementalId: "or-filter-incremental-id",
  ORDER_FILTER_INCREMENTAL_OMNIX_ID: "RSC.ORDER.FILTER.INCREMENTAL_OMNIX_ID",

  OrFilterCustomTps: "or-filter-custom-tps",
  ORDER_FILTER_CUSTOM_TPS: "RSC.ORDER.FILTER.CUSTOM_TPS",

  ORDER_PAGE_LOGISTIC: "RSC.ORDER.PAGE.LOGISTIC",
  ORDER_PAGE_OPERATOR: "RSC.ORDER.PAGE.OPERATOR",

  OrHideCreateOrderButton: "or-hide-create-order-button",
  ORDER_BUTTON_CREATE_ORDER_HIDE: "RSC.ORDER.BUTTON.CREATE_ORDER.HIDE",

  OrCrudCreateSimpleOrder: "or-crud-create-simple-order",
  ORDER_BUTTON_CREATE_ORDER: "RSC.ORDER.BUTTON.CREATE_ORDER",

  OrCrudCreateTransferOrder: "or-crud-create-transfer-order",
  ORDER_BUTTON_CREATE_TRANSFER_ORDER: "RSC.ORDER.BUTTON.CREATE_TRANSFER_ORDER",

  OrCrudCreatePosOrder: "or-crud-create-pos-order",
  ORDER_BUTTON_CREATE_POS_ORDER: "RSC.ORDER.BUTTON.CREATE_POS_ORDER",

  OrCrudEditOrder: "or-crud-edit-order",
  ORDER_BUTTON_EDIT_ORDER: "RSC.ORDER.BUTTON.EDIT_ORDER",

  OrActionsConfirmOrder: "or-actions-confirm-order",
  ORDER_BUTTON_CONFIRM_ORDER: "RSC.ORDER.BUTTON.CONFIRM_ORDER",

  OrActionsCancelOrder: "or-actions-cancel-order",
  ORDER_BUTTON_CANCEL_ORDER: "RSC.ORDER.BUTTON.CANCEL_ORDER",

  // SG
  SgListSg: "sg-list-sg",
  SG_LIST_SG_LIST: "RSC.SG.LIST.SG_LIST",
  SG_LIST_SG_SHOW: "RSC.SG.LIST.SG_SHOW",
  SG_LIST_SG_LIST_ACCEPT: "RSC.SG.LIST.SG_LIST_ACCEPT",

  SgFilterSgId: "sg-filter-sg-id",
  SG_FILTER_SG_ID: "RSC.SG.FILTER.SG_ID",

  SgFilterChannelId: "sg-filter-channel-id",
  SG_FILTER_CHANNEL: "RSC.SG.FILTER.CHANNEL",

  SgFilterStatus: "sg-filter-status",
  SG_FILTER_CURRENT_STATUS: "RSC.SG.FILTER.CURRENT_STATUS",

  SgFilterSource: "sg-filter-source",
  SG_FILTER_SOURCE_ID: "RSC.SG.FILTER.SOURCE_ID",

  SgFilterSalesChannelId: "sg-filter-sales-channel-id",
  SG_FILTER_SALES_CHANNEL_ID: "RSC.SG.FILTER.SALES_CHANNEL_ID",

  SgFilterIncrementalId: "sg-filter-incremental-id",
  SG_FILTER_INCREMENTAL_OMNIX_ID: "RSC.SG.FILTER.INCREMENTAL_OMNIX_ID",

  SgActionReschedule: "sg-action-reschedule",
  SG_BUTTON_RESCHEDULE_SG: "RSC.SG.BUTTON.RESCHEDULE_SG",

  SgCrudCreationOrderRt: "sg-crud-creation-order-rt",
  SG_BUTTON_CREATE_RETURN_ORDER: "RSC.SG.BUTTON.CREATE_RETURN_ORDER",

  LogisticGroup: "logistic-group",
  LOGISTIC_BUTTON_GROUP_SIDEBAR: "RSC.LOGISTIC.BUTTON_GROUP.SIDEBAR",
  LOGISTIC_ITEM_SCHEDULE_SIDEBAR: "RSC.LOGISTIC.ITEM.SCHEDULE.SIDEBAR",
  LOGISTIC_ITEM_SOURCES_SIDEBAR: "RSC.LOGISTIC.ITEM.SOURCES.SIDEBAR",
  LOGISTIC_ITEM_COURIERS_SIDEBAR: "RSC.LOGISTIC.ITEM.COURIERS.SIDEBAR",
  LOGISTIC_ITEM_CAPACITY_USED_SIDEBAR: "RSC.LOGISTIC.ITEM.CAPACITY_USED.SIDEBAR",
  LOGISTIC_ITEM_ROUTES_SIDEBAR: "RSC.LOGISTIC.ITEM.ROUTES.SIDEBAR",
  LOGISTIC_ITEM_COVERAGE_SIDEBAR: "RSC.LOGISTIC.ITEM.COVERAGE.SIDEBAR",
  LOGISTIC_ITEM_LOCATIONS_SIDEBAR: "RSC.LOGISTIC.ITEM.LOCATIONS.SIDEBAR",
  LOGISTIC_ITEM_CALENDAR_SIDEBAR: "RSC.LOGISTIC.ITEM.CALENDAR.SIDEBAR",

  // SOURCE
  SoFilterSourceId: "so-filter-source-id",
  SOURCE_FILTER_SOURCE_ID: "RSC.SOURCE.FILTER.SOURCE_ID",

  SoFilterSourceName: "so-filter-source-name",
  SOURCE_FILTER_SOURCE_NAME: "RSC.SOURCE.FILTER.SOURCE_NAME",

  SoFilterSourceStatus: "so-filter-source-status",
  SOURCE_FILTER_SOURCE_STATUS: "RSC.SOURCE.FILTER.SOURCE_STATUS",

  SoCrudCreateSource: "so-crud-create-source",
  SOURCE_BUTTON_CREATE_SOURCE: "RSC.SOURCE.BUTTON.CREATE_SOURCE",

  SoCrudEditSource: "so-crud-edit-source",
  SOURCE_BUTTON_EDIT_SOURCE: "RSC.SOURCE.BUTTON.EDIT_SOURCE",

  SoCrudDeleteSource: "so-crud-delete-source",
  SOURCE_BUTTON_DELETE_SOURCE: "RSC.SOURCE.BUTTON.DELETE_SOURCE",

  SoListSource: "so-list-source",
  SOURCE_LIST_SOURCE_LIST: "RSC.SOURCE.LIST.SOURCE_LIST",
  SOURCE_LIST_SOURCE_SHOW: "RSC.SOURCE.LIST.SOURCE_SHOW",

  SoListHandleMultipleSources: "so-list-handle-multiple-sources",
  SOURCE_CHECKBOX_HANDLE_SOURCE_ACTION: "RSC.SOURCE.CHECKBOX.HANDLE_SOURCE_ACTION",

  // CAPACITY
  ScFilterCapacityId: "sc-filter-capacity-id",
  CAPACITY_FILTER_CAPACITY_ID: "RSC.CAPACITY.FILTER.CAPACITY_ID",

  ScFilterCapacityDate: "sc-filter-capacity-date",
  CAPACITY_FILTER_CAPACITY_DATE: "RSC.CAPACITY.FILTER.CAPACITY_DATE",

  ScCrudEditSourceCapacity: "sc-crud-edit-source-capacity",
  CAPACITY_BUTTON_EDIT_CAPACITY: "RSC.CAPACITY.BUTTON.EDIT_CAPACITY",

  // CALENDAR
  CaListCalendar: "ca-list-calendar",
  CALENDAR_LIST_CALENDAR_LIST: "RSC.CALENDAR.LIST.CALENDAR_LIST",

  CaCrudEditCalendar: "ca-crud-edit-calendar",
  CALENDAR_BUTTON_EDIT_CALENDAR: "RSC.CALENDAR.BUTTON.EDIT_CALENDAR",

  // ROUTE
  RoListRouteList: "ro-list-route-list",
  ROUTE_LIST_ROUTE_LIST: "RSC.ROUTE.LIST.ROUTE_LIST",

  RoFilterRouteId: "ro-filter-route-id",
  ROUTE_FILTER_ROUTE_ID: "RSC.ROUTE.FILTER.ROUTE_ID",

  RoFilterSource: "ro-filter-source",
  ROUTE_FILTER_ROUTE_SOURCE: "RSC.ROUTE.FILTER.ROUTE_SOURCE",

  RoFilterTarget: "ro-filter-target",
  ROUTE_FILTER_ROUTE_TARGET: "RSC.ROUTE.FILTER.ROUTE_TARGET",

  RoFilterCreateRoute: "ro-filter-create-route",
  ROUTE_BUTTON_CREATE_ROUTE: "RSC.ROUTE.BUTTON.CREATE_ROUTE",

  RoFilterEditRoute: "ro-filter-edit-route",
  ROUTE_BUTTON_EDIT_ROUTE: "RSC.ROUTE.BUTTON.EDIT_ROUTE",

  // COVERAGE
  CoListCoverage: "co-list-coverage",
  COVERAGE_LIST_COVERAGE_LIST: "RSC.COVERAGE.LIST.COVERAGE_LIST",

  CoFilterCoverageId: "co-filter-coverage-id",
  COVERAGE_FILTER_COVERAGE_ID: "RSC.COVERAGE.FILTER.COVERAGE_ID",

  CoFilterCourierId: "co-filter-courier-id",
  COVERAGE_FILTER_COVERAGE_COURIER: "RSC.COVERAGE.FILTER.COVERAGE_COURIER",

  CoFilterRouteId: "co-filter-route-id",
  COVERAGE_FILTER_COVERAGE_ROUTE: "RSC.COVERAGE.FILTER.COVERAGE_ROUTE",

  CoFilterShippingType: "co-filter-shipping-type",
  COVERAGE_FILTER_SHIPPING_TYPE: "RSC.COVERAGE.FILTER.SHIPPING_TYPE",

  CoFilterStatus: "co-filter-status",
  COVERAGE_FILTER_STATUS: "RSC.COVERAGE.FILTER.STATUS",

  CoFilterDeliveryType: "co-filter-delivery-type",
  COVERAGE_FILTER_DELIVERY_TYPE: "RSC.COVERAGE.FILTER.DELIVERY_TYPE",

  CoCrudCreateCoverage: "co-crud-create-coverage",
  COVERAGE_BUTTON_CREATE_COVERAGE: "RSC.COVERAGE.BUTTON.CREATE_COVERAGE",

  CoCrudEditCoverage: "co-crud-edit-coverage",
  COVERAGE_BUTTON_EDIT_COVERAGE: "RSC.COVERAGE.BUTTON.EDIT_COVERAGE",

  // COURIER
  CourListCouriers: "cour-list-couriers",
  COURIER_LIST_COURIER_LIST: "RSC.COURIER.LIST.COURIER_LIST",

  CourFilterCourierId: "cour-filter-courier-id",
  COURIER_FILTER_COURIER_ID: "RSC.COURIER.FILTER.COURIER_ID",

  CourFilterAlias: "cour-filter-alias",
  COURIER_FILTER_COURIER_ALIAS: "RSC.COURIER.FILTER.COURIER_ALIAS",

  CourCrudCreateCourier: "cour-crud-create-courier",
  COURIER_BUTTON_CREATE_COURIER: "RSC.COURIER.BUTTON.CREATE_COURIER",

  CourCrudEditCourier: "cour-crud-edit-courier",
  COURIER_BUTTON_EDIT_COURIER: "RSC.COURIER.BUTTON.EDIT_COURIER",

  // LOCATION
  LoListCriteria1: "lo-list-criteria-1",
  LOCATION_FILTER_CRITERIA1: "RSC.LOCATION.FILTER.CRITERIA1",

  LoListCriteria2: "lo-list-criteria-2",
  LOCATION_FILTER_CRITERIA2: "RSC.LOCATION.FILTER.CRITERIA2",

  LoListCriteria3: "lo-list-criteria-3",
  LOCATION_FILTER_CRITERIA3: "RSC.LOCATION.FILTER.CRITERIA3",

  LoListLocation: "lo-list-location",
  LOCATION_LIST_LOCATION_LIST: "RSC.LOCATION.LIST.LOCATION_LIST",

  LoCrudCreateLocation: "lo-crud-create-location",
  LOCATION_BUTTON_CREATE_LOCATION: "RSC.LOCATION.BUTTON.CREATE_LOCATION",

  LoCrudEditLocation: "lo-crud-edit-location",
  LOCATION_BUTTON_EDIT_LOCATION: "RSC.LOCATION.BUTTON.EDIT_LOCATION",

  LoCrudDeleteLocation: "lo-crud-delete-location",
  LOCATION_BUTTON_DELETE_LOCATION: "RSC.LOCATION.BUTTON.DELETE_LOCATION",

  // SCHEDULE
  SchCrudList: "sch-crud-list",
  SCHEDULE_LIST_SCHEDULE_LIST: "RSC.SCHEDULE.LIST.SCHEDULE_LIST",

  SchCrudCreate: "sch-crud-create",
  SCHEDULE_BUTTON_CREATE_SCHEDULE: "RSC.SCHEDULE.BUTTON.CREATE_SCHEDULE",

  SchCrudEdit: "sch-crud-edit",
  SCHEDULE_BUTTON_EDIT_SCHEDULE: "RSC.SCHEDULE.BUTTON.EDIT_SCHEDULE",

  SchCrudDelete: "sch-crud-delete",
  SCHEDULE_BUTTON_DELETE_SCHEDULE: "RSC.SCHEDULE.BUTTON.DELETE_SCHEDULE",

  InventoryGroup: "inventory-group",
  INVENTORY_BUTTON_GROUP_SIDEBAR: "RSC.INVENTORY.BUTTON_GROUP.SIDEBAR",
  INVENTORY_ITEM_SALES_CHANNEL_SIDEBAR: "RSC.INVENTORY.ITEM.SALES_CHANNEL.SIDEBAR",
  INVENTORY_ITEM_STOCK_SIDEBAR: "RSC.INVENTORY.ITEM.STOCK.SIDEBAR",
  INVENTORY_ITEM_ARTICLES_SIDEBAR: "RSC.INVENTORY.ITEM.ARTICLES.SIDEBAR",

  // CHANNEL
  ChListChannel: "ch-list-channel",
  CHANNEL_LIST_CHANNEL_LIST: "RSC.CHANNEL.LIST.CHANNEL_LIST",

  ChFilterChannelId: "ch-filter-channel-id",
  CHANNEL_FILTER_CHANNEL_ID: "RSC.CHANNEL.FILTER.CHANNEL_ID",

  ChCrudCreate: "ch-crud-create",
  CHANNEL_BUTTON_CREATE_CHANNEL: "RSC.CHANNEL.BUTTON.CREATE_CHANNEL",

  ChCrudEdit: "ch-crud-edit",
  CHANNEL_BUTTON_EDIT_CHANNEL: "RSC.CHANNEL.BUTTON.EDIT_CHANNEL",

  ChCrudDelete: "ch-crud-delete",
  CHANNEL_BUTTON_DELETE_CHANNEL: "RSC.CHANNEL.BUTTON.DELETE_CHANNEL",

  // STOCK
  StoFilterStockId: "sto-filter-stock-id",
  STOCK_FILTER_STOCK_ID: "RSC.STOCK.FILTER.STOCK_ID",

  StoCrudCreate: "sto-crud-create",
  STOCK_BUTTON_CREATE_STOCK: "RSC.STOCK.BUTTON.CREATE_STOCK",
  STOCK_FILTER_STOCK_SHOW: "RSC.STOCK.BUTTON.SHOW_STOCK",

  StoCrudEdit: "sto-crud-edit",
  STOCK_BUTTON_EDIT_STOCK: "RSC.STOCK.BUTTON.EDIT_STOCK",

  StoCrudDelete: "sto-crud-delete",
  STOCK_BUTTON_DELETE_STOCK: "RSC.STOCK.BUTTON.DELETE_STOCK",

  // ITEM
  ItListItems: "it-list-items",
  ITEM_LIST_ITEM_LIST: "RSC.ITEM.LIST.ITEM_LIST",

  ItFilterChannelId: "it-filter-channel-id",
  ITEM_FILTER_CHANNEL_ID: "RSC.ITEM.FILTER.CHANNEL_ID",

  ItFilterSku: "it-filter-sku",
  ITEM_FILTER_SKU: "RSC.ITEM.FILTER.SKU",

  ItFilterCategory: "it-filter-category",
  ITEM_FILTER_CATEGORY: "RSC.ITEM.FILTER.CATEGORY",

  ItFilterItemType: "it-filter-item-type",
  ITEM_FILTER_TYPE: "RSC.ITEM.FILTER.TYPE",

  ItFilterItemStatus: "it-filter-item-status",
  ITEM_FILTER_ENABLED: "RSC.ITEM.FILTER.ENABLED",

  ItFilterStorePickup: "it-filter--store-pickup",
  ITEM_FILTER_STORE_PICKUP: "RSC.ITEM.FILTER.STORE_PICKUP",

  ItFilterVv: "it-filter--vv",
  ITEM_FILTER_CUSTOM_VENTA_VERDE: "RSC.ITEM.FILTER.CUSTOM_VENTA_VERDE",

  ItFilterRut: "it-filter-rut",
  ITEM_FILTER_CUSTOM_RUT: "RSC.ITEM.FILTER.CUSTOM_RUT",

  ItFilterFamily: "it-filter-family",
  ITEM_FILTER_CUSTOM_FAMILY: "RSC.ITEM.FILTER.CUSTOM_FAMILY",

  ItCrudEditItem: "it-crud-edit-item",
  ITEM_BUTTON_EDIT_ITEM: "RSC.ITEM.BUTTON.EDIT_ITEM",

  ItCrudCreateItem: "it-crud-create-item",
  ITEM_BUTTON_CREATE_ITEM: "RSC.ITEM.BUTTON.CREATE_ITEM",

  ItCrudDeleteItem: "it-crud-delete-item",
  ITEM_BUTTON_DELETE_ITEM: "RSC.ITEM.BUTTON.DELETE_ITEM",

  ItActionSafetyStockChannelSource: "it-action-safety-stock-channel-source",
  ITEM_BUTTON_ASSING_ITEM_SAFETY_STOCK: "RSC.ITEM.BUTTON.ASSING_ITEM_SAFETY_STOCK",

  LoadGroup: "load-group",
  MASSIVE_LOAD_BUTTON_SIDEBAR: "RSC.MASSIVE_LOAD.BUTTON.SIDEBAR",

  // MASSIVE_LOAD
  CmCrudLoadRoutecoverage: "cm-crud-load-routecoverage",
  MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD: "RSC.MASSIVE_LOAD.BUTTON.CREATE_MASSIVE_LOAD",

  CmListLoadRouteCoverage: "cm-list-load-routecoverage",
  MASSIVE_LOAD_LIST_MASSIVE_LOAD_LIST: "RSC.MASSIVE_LOAD.LIST.MASSIVE_LOAD_LIST",

  CmFilterCmId: "cm-filter-cm-id",
  MASSIVE_LOAD_FILTER_MASSIVE_LOAD_ID: "RSC.MASSIVE_LOAD.FILTER.MASSIVE_LOAD_ID",

  ReportGroup: "report-group",
  REPORT_BUTTON_SIDEBAR: "RSC.REPORT.BUTTON.SIDEBAR",
  // REPORT
  ReportOperation: "report-operation",
  REPORT_OPTION_ORDER_OPERATION: "RSC.REPORT.OPTION.ORDER_OPERATION",

  ReportOrder: "report-order",
  REPORT_OPTION_ORDERS: "RSC.REPORT.OPTION.ORDERS",

  ReportOrderDetail: "report-order-detail",
  REPORT_OPTION_ITEM_ORDER: "RSC.REPORT.OPTION.ITEM_ORDER",

  Disponibility: "disponibility",
  REPORT_OPTION_AVAILABILITY: "RSC.REPORT.OPTION.AVAILABILITY",

  OrderReserve: "order-reserve",
  REPORT_OPTION_ORDERS_IN_RESERVE: "RSC.REPORT.OPTION.ORDERS_IN_RESERVE",

  RequestMs: "request-ms",
  REPORT_OPTION_OUTPUT_OF_SERVICE_REQUEST: "RSC.REPORT.OPTION.OUTPUT_OF_SERVICE_REQUEST",

  ReportItems: "report-items",
  REPORT_OPTION_ITEMS: "RSC.REPORT.OPTION.ITEMS",

  Transactions: "transactions",
  REPORT_OPTION_TRANSACTIONS: "RSC.REPORT.OPTION.TRANSACTIONS",

  ReportCapacity: "report-capacity",
  REPORT_OPTION_CAPACITY: "RSC.REPORT.OPTION.CAPACITY",


  // SETTING
  SettingGroup: "setting-group",
  SETTING_BUTTON_SIDEBAR: "RSC.SETTING.BUTTON.SIDEBAR",
  
  Setting: "setting",
  SETTING_BUTTON_SET_INCREMENTAL_OMNIX_ID: "RSC.SETTING.BUTTON.SET_INCREMENTAL_OMNIX_ID",

  SettingView: "settingview",
  SETTING_PAGE_DETAIL_INCREMENTAL_OMNIX_ID: "RSC.SETTING.PAGE.DETAIL_INCREMENTAL_OMNIX_ID",

  SETTING_PAGE_CUSTOM_SET_RED_BUTTON: "RSC.SETTING.PAGE.CUSTOM_SET_RED_BUTTON",

  SETTING_PAGE_HOME_SECURITY_SCHEMAS: "RSC.SETTING.PAGE.HOME_SECURITY_SCHEMAS",
  SETTING_USER_LIST_SECURITY_SCHEMAS: "RSC.SETTING.USER_LIST_SECURITY_SCHEMAS",
  SETTING_USER_EDIT_SECURITY_SCHEMAS: "RSC.SETTING.USER_EDIT_SECURITY_SCHEMAS",
  SETTING_USER_DELETE_SECURITY_SCHEMAS: "RSC.SETTING.USER_DELETE_SECURITY_SCHEMAS",
  SETTING_USER_ENABLE_SECURITY_SCHEMAS: "RSC.SETTING.USER_ENABLE_SECURITY_SCHEMAS",
  SETTING_USER_NEW_SECURITY_SCHEMAS: "RSC.SETTING.USER_NEW_SECURITY_SCHEMAS",
  SETTING_USER_SHOW_SECURITY_SCHEMAS: "RSC.SETTING.USER_SHOW_SECURITY_SCHEMAS",
  SETTING_LIST_ROLE_LIST_SECURITY_SCHEMAS: "RSC.SETTING.LIST.ROLE_LIST_SECURITY_SCHEMAS",
}

export default resources
